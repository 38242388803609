<div class="forgotpassword">
  <app-shared-notification-component
    *ngIf="viewState.responseMessage"
    [config]="viewState.responseMessage"
  >
  </app-shared-notification-component>
  <div class="forgotpassword-container">
    <div class="forgotpassword-header">
      <img
        loading="lazy"
        src="{{ viewState.assetsRelativePath + 'forgotpassword.svg' }}"
        alt="forgot password"
        class="forgotpassword-header__img"
      />
      <h4 class="forgotpassword-header__heading body2--bold">
        {{ trans('auth.forgotPasswordHeader') }}
      </h4>
    </div>
    <form [formGroup]="forgotPasswordForm">
      <div
        class="forgotpassword-actionbox"
        [ngClass]="viewState.isResetViaEmail ? 'forgotpassword-actionbox__focused' : ''"
      >
        <div (click)="switchResetMethod('email')" class="forgotpassword-actionbox-button">
          <div class="forgotpassword-actionbox-button">
            <img
              src="{{
                viewState.isResetViaEmail
                  ? viewState.assetsRelativePath + 'envelop.svg'
                  : viewState.assetsRelativePath + 'envelop-gray.svg'
              }}"
            />
          </div>
          <p class="forgotpassword-actionbox-button__text body2--bold">
            {{ trans('auth.sendViaEmail') }}
          </p>
          <mat-radio-button
            [checked]="viewState.isResetViaEmail"
            class="forgotpassword-actionbox-button__radio"
          >
          </mat-radio-button>
        </div>
        <input
          *ngIf="viewState.isResetViaEmail"
          class="forgotpassword-actionbox__input body2--medium"
          [placeholder]="trans('email')"
          type="email"
          formControlName="email"
        />
      </div>
    </form>
    <button
      (click)="onSubmit()"
      class="forgotpassword-container__submit-button body2--medium"
      type="submit"
      [disabled]="!viewState.formIsValid || viewState.isLoading"
    >
      {{ trans('send') }}
    </button>
  </div>

  <!-- To be done when the backend finalize the API -->
  <ng-template>
    <div
      class="forgotpassword-actionbox"
      [ngClass]="!viewState.isResetViaEmail ? 'forgotpassword-actionbox__focused' : ''"
    >
      <div (click)="switchResetMethod('phone')" class="forgotpassword-actionbox-button">
        <div class="forgotpassword-actionbox-button-iconcontainer">
          <i class="icon icon-auth-phone forgotpassword-actionbox-button-iconcontainer__icon"></i>
        </div>
        <p class="forgotpassword-actionbox-button__text">{{ trans('auth.sendViaPhone') }}</p>
        <mat-radio-button
          [checked]="!viewState.isResetViaEmail"
          class="forgotpassword-actionbox-button__radio"
        >
        </mat-radio-button>
      </div>
      <app-meta-phone-number-field
        *ngIf="!viewState.isResetViaEmail"
        [showValidationErrorIndicator]="true"
        [fieldIsValid]="true"
        (result$)="phoneNumberChanges($event)"
      >
      </app-meta-phone-number-field>
    </div>
  </ng-template>
</div>
