import { Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';

import { NgIf } from '@angular/common';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BaseComponent } from '../../base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import {
  MetaPhoneNumberFieldComponent,
  MetaPhoneNumberOutput,
} from '../../shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { SharedNotificationComponent } from '../shared/notification/shared.notification.component';
import { LoginPresenter } from './presentation/login.presenter';
import { LoginSideEffects } from './presentation/login.side-effects';
import { LoginViewEvent } from './presentation/login.view-events';
import { LoginViewState } from './presentation/login.view-state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SharedNotificationComponent,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    MetaPhoneNumberFieldComponent,
    RouterLink,
  ],
  providers: [LoginPresenter],
})
export class LoginComponent extends BaseComponent<
  LoginPresenter,
  LoginViewState,
  LoginViewEvent,
  LoginSideEffects
> {
  public loginForm: UntypedFormGroup = new UntypedFormGroup({
    phoneNumberOrEmail: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  public phonePrefix: number;

  public userPhoneNumber: number | null;

  public presenter: LoginPresenter = inject(LoginPresenter);

  private _logGTagEventUseCase: LogGTagEventUseCase = inject(LogGTagEventUseCase);

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  get phoneNumberOrEmail(): AbstractControl {
    return this.loginForm.get('phoneNumberOrEmail')!;
  }

  get password(): AbstractControl {
    return this.loginForm.get('password')!;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  onSideEffect(sideEffect: LoginSideEffects): void {
    switch (sideEffect.type) {
      case 'FireAnalyticsEvent': {
        if (sideEffect.eventType === 'Mixpanel') {
          this._logMixpanelEventUseCase.execute({
            eventName: sideEffect.eventName,
            payload: sideEffect.payload,
          });
        } else {
          this._logGTagEventUseCase.execute({
            eventName: sideEffect.eventName,
            payload: sideEffect.payload,
          });
        }
        break;
      }
    }
  }

  public showHidePassword(): void {
    this.presenter.emitViewEvent({ type: 'ToggleShowHidePassword' });
  }

  signInWithGoogle(): void {
    this.presenter.emitViewEvent({ type: 'LoginWithGoogle' });
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }
    const userLogin: { [attr: string]: any } = {
      password: this.loginForm.value.password,
    };
    if (this.viewState.selectedLoginMethod === 'email') {
      userLogin.email = this.loginForm.value.phoneNumberOrEmail?.toString();
    } else if (this.viewState.selectedLoginMethod === 'phone') {
      userLogin.callingCode = this.phonePrefix?.toString();
      userLogin.phoneNumber = this.loginForm.value.phoneNumberOrEmail?.toString();
    }
    this.userPhoneNumber = this.loginForm.value.phoneNumberOrEmail;

    this.presenter.emitViewEvent({ type: 'Submit', credentials: userLogin });
  }

  public changeLoginType(): void {
    this.presenter.emitViewEvent({ type: 'LoginMethodChanged' });
    this.loginForm.get('phoneNumberOrEmail')?.reset('', { emitEvent: false });
    this.loginForm.markAsPristine();
    this.userPhoneNumber = null;
  }

  public phoneNumberChanges(ev: MetaPhoneNumberOutput): void {
    this.loginForm.get('phoneNumberOrEmail')?.patchValue(ev.phoneNumber);
    this.phonePrefix = ev.country.phoneNumPrefix;
  }

  onGoToSignUpClicked(): void {
    this.presenter.emitViewEvent({ type: 'GoToRegisterClicked' });
  }
}
