import { inject } from '@angular/core';
import { Observable, take } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { LOGIN_REDIRECT_TO_ANDROID } from 'src/app/presentation/shared/constants';
import { REDIRECT_USER_TO_MOBILE_APPS } from 'src/app/presentation/shared/constants/feature-flags';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';

type PlatformTypes = 'android' | 'ios' | 'other';

const userIsOnSupportedMobileResponsive = (): {
  verdict: boolean;
  platform: PlatformTypes;
} => {
  const responsiveService: ResponsiveService = inject(ResponsiveService);
  const verdict: boolean = responsiveService.returnDeviceCategory() === 'mobile';
  let platform: PlatformTypes;
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    platform = 'android';
  } else if (userAgent.includes('iphone')) {
    platform = 'ios';
  } else {
    platform = 'other';
  }
  return { verdict, platform };
};

const redirectUserToSupportedPlatform = (
  logMixpanelEventUseCase: LogMixpanelEventUseCase,
  platform: PlatformTypes,
  url: string,
) => {
  logMixpanelEventUseCase.execute({
    eventName: 'web_login_page_redirect_to',
    payload: { platform },
  });
  window.location.replace(url);
};

export const mobileDeviceRedirectResolver = (): Observable<boolean> => {
  const getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);
  const logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);
  const userIsOnMobileDevice = userIsOnSupportedMobileResponsive();
  return new Observable((subscriber) => {
    if (!userIsOnMobileDevice.verdict) {
      subscriber.next(true);
    } else {
      getFeatureFlagUsecase
        .execute(REDIRECT_USER_TO_MOBILE_APPS)
        .pipe(take(1))
        .subscribe({
          next: (redirectFlagIsOn) => {
            if (redirectFlagIsOn) {
              switch (userIsOnMobileDevice.platform) {
                case 'android':
                  redirectUserToSupportedPlatform(
                    logMixpanelEventUseCase,
                    'android',
                    LOGIN_REDIRECT_TO_ANDROID,
                  );
                  break;
                default: {
                  subscriber.next(true);
                  break;
                }
              }
            } else {
              subscriber.next(true);
            }
          },
        });
    }
  });
};
