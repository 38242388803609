import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideRouter } from '@angular/router';
import { mobileDeviceRedirectResolver } from '../shared/guards/v2/resolvers/shared/mobile-device-redirect.resolver';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    provideRouter([
      {
        path: 'signup',
        canLoad: [mobileDeviceRedirectResolver],
        loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password/:id/:resetToken',
        loadChildren: () =>
          import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [mobileDeviceRedirectResolver],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ]),
  ],
})
export class AuthModule {}
