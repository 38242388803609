// login Presenter

import { Injectable } from '@angular/core';
import { trace } from '@angular/fire/compat/performance';
import { user } from '@features/user/data';
import cache from '@mongez/cache';
import { trans } from '@mongez/localization';
import { navigateTo, queryString } from '@presentation/shared/router';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { getCountryFromIsoCode2 } from '@taager-experience-shared/country-resolver';
// @ts-ignore

import { countriesList, country } from '@features/country/data';
import { AppTracker } from 'app/components/tracking/app-tracker';
import { IdentifyMixpanelInstanceUseCase } from 'app/core/usecases/analytics/identify-mixpanel-instance.usecase';
import { GetRegisterTokenUseCase } from 'app/core/usecases/auth/get-register-token.usecase';
import { LoginUserWithGoogleUseCase } from 'app/core/usecases/auth/login-user-with-google.usecase';
import { LoginAuthUserUseCase } from 'app/core/usecases/auth/login-user.usecase';
import { CheckUserNewExperienceEligibilityUseCase } from 'app/core/usecases/products/check-user-new-experience-eligibility.usecase';
import { SetSelectedProductsPageExperienceUseCase } from 'app/core/usecases/products/set-selected-products-page-experience.usecase';
import { GetUserCountryUseCase } from 'app/core/usecases/user-location/get-user-country.usecase';
import { PossibleIndicatorSteps } from 'app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { PRODUCTS_V2_URL, REDIRECT_URL, REGISTER_URL } from 'app/presentation/shared/constants';
import { MixpanelService } from 'app/presentation/shared/services/mixpanel.service';
import { MultitenancyService } from 'app/presentation/shared/services/multitenancy.service';
import { ResponsiveService } from 'app/presentation/shared/services/responsive.service';
import { resolveUserLandingUtility } from 'app/presentation/shared/utilities/resolve-user-landing.utility';
import { UserRegistrationStepUtility } from 'app/presentation/shared/utilities/user-registration-step.utility';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { BasePresenter, ViewEventHandler } from 'src/app/presentation/base/base.presenter';
import { LOGIN_VIA_EMAIL, LOGIN_VIA_PHONE } from 'src/app/presentation/shared/constants/auth';
import { SharedNotificationConfig } from '../../shared/notification/shared.notification.component';
import { LoginSideEffects } from './login.side-effects';
import { LoginViewEvent } from './login.view-events';
import { LoginViewState } from './login.view-state';

@Injectable({ providedIn: 'root' })
export class LoginPresenter extends BasePresenter<
  LoginViewState,
  LoginViewEvent,
  LoginSideEffects
> {
  constructor(
    private _loginAuthUserUseCase: LoginAuthUserUseCase,
    private _multitenancyService: MultitenancyService,
    private _setSelectedProductsPageExperienceUseCase: SetSelectedProductsPageExperienceUseCase,
    private _checkUserNewExperienceEligibilityUseCase: CheckUserNewExperienceEligibilityUseCase,
    private _responsiveService: ResponsiveService,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _loginUserWithGoogleUseCase: LoginUserWithGoogleUseCase,
    private _identifyMixpanelInstanceUseCase: IdentifyMixpanelInstanceUseCase,
    private _appTracker: AppTracker,
    private _getRegisterTokenUseCase: GetRegisterTokenUseCase,
    private _userRegistrationStepUtility: UserRegistrationStepUtility,
    private _mixpanelService: MixpanelService,
  ) {
    super();
  }

  protected defaultViewState(): LoginViewState {
    return {
      selectedLoginMethod: 'phone',
      loginText: LOGIN_VIA_EMAIL,
      assetsRelativePath: '/assets/img/auth/',
      showPassword: false,
      isSubmitted: false,
      showResetPasswordError: false,
      isLoading: false,
      responseMessage: undefined,
    };
  }

  protected viewEvents: ViewEventHandler<LoginViewEvent> = {
    Init: this.handleOnInit,
    LoginMethodChanged: this.loginMethodChanged,
    ToggleShowHidePassword: () => this.set('showPassword', !this.get('showPassword')),
    LoginWithGoogle: this._signInWithGoogle,
    Submit: (event) => this._onSubmit(event.credentials),
    GoToRegisterClicked: this._onGoToSignUpClicked,
  };

  protected loginMethodChanged(): void {
    const currentMethod = this.get('selectedLoginMethod');
    const newMethod = currentMethod === 'email' ? 'phone' : 'email';

    this.merge({
      selectedLoginMethod: newMethod,
      loginText: newMethod === 'email' ? LOGIN_VIA_PHONE : LOGIN_VIA_EMAIL,
      showResetPasswordError: false,
      responseMessage: undefined,
    });
  }

  protected handleOnInit(): void {
    if (queryString.get('reset') === 'true') {
      this.merge({
        responseMessage: {
          msg: trans('auth.resetPasswordSuccess'),
          status: 'success',
          iconMeta: {
            icon: 'assets/img/auth/success-check-white.svg',
            position: 'before',
          },
        },
      });
    }

    this.emitSideEffect({
      type: 'FireAnalyticsEvent',
      eventType: 'Mixpanel',
      eventName: 'login_page_load',
      payload: {
        Status: 'login page',
      },
    });
  }

  private _onGoToSignUpClicked(): void {
    const landingCountry = getCountryFromIsoCode2(
      resolveUserLandingUtility().code.toUpperCase(),
    ).isoCode3;

    navigateTo(REGISTER_URL);

    this.emitSideEffect({
      type: 'FireAnalyticsEvent',
      eventType: 'Mixpanel',
      eventName: 'login-go-to-register-click',
      payload: {
        landing_page_country: landingCountry,
      },
    });
  }

  private _onSubmit(credentials: { [attr: string]: any }): void {
    this.merge({
      isSubmitted: true,
      showResetPasswordError: false,
      responseMessage: undefined,
      isLoading: true,
    });

    this._loginAuthUserUseCase
      .execute(credentials as any)
      .pipe(
        // measure the amount of time be
        trace('login-user'),
      )
      .subscribe({
        next: async () => {
          this._setSelectedProductsPageExperience();
          await countriesList.load();
          this._routeAfterLogin();
          if (this.get('selectedLoginMethod') === 'email') {
            this.emitSideEffect({
              type: 'FireAnalyticsEvent',
              eventType: 'Mixpanel',
              eventName: 'login_email',
              payload: {
                status: 'logged in',
              },
            });

            this.emitSideEffect({
              type: 'FireAnalyticsEvent',
              eventType: 'Gtag',
              eventName: 'sign_in',
              payload: {
                'Taager ID': user.id,
                Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
                'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
              },
            });
          } else if (this.viewState.selectedLoginMethod === 'phone') {
            if (user.isDataVerified && user.isPhoneNumberVerified) {
              this.emitSideEffect({
                type: 'FireAnalyticsEvent',
                eventType: 'Mixpanel',
                eventName: 'login_mobile_fully_verified',
                payload: {
                  status: 'logged in',
                },
              });
            } else {
              this.emitSideEffect({
                type: 'FireAnalyticsEvent',
                eventType: 'Mixpanel',
                eventName: 'login_mobile_unverified',
                payload: {
                  status: 'logged in',
                },
              });
            }
          } else {
            this.emitSideEffect({
              type: 'FireAnalyticsEvent',
              eventType: 'Mixpanel',
              eventName: 'login_mobile_legacy_user',
              payload: {
                status: 'logged in',
              },
            });
          }

          //  There is no need to disable the loading state here, as the browser will perform a full page reload
          // this.set('isLoading', false);
        },
        error: (error) => {
          let responseMessage: SharedNotificationConfig | undefined;
          if (error.status === 401 || error.status === 422) {
            if (this.get('selectedLoginMethod') === 'email') {
              this.emitSideEffect({
                type: 'FireAnalyticsEvent',
                eventType: 'Mixpanel',
                eventName: 'login_error_wrong_email_password',
                payload: {
                  Status: 'Incorrect email or password',
                  login_using: 'normal',
                },
              });
            } else {
              this.emitSideEffect({
                type: 'FireAnalyticsEvent',
                eventType: 'Mixpanel',
                eventName: 'login_error_wrong_phone_password',
                payload: {
                  Status: 'Incorrect phone number or password',
                  login_using: 'normal',
                },
              });
            }
          } else if (error.status === 403) {
            responseMessage = {
              msg: trans('auth.unauthorizedToAccess'),
              status: 'error',
              iconMeta: {
                icon: 'assets/img/auth/danger-white.svg',
                position: 'before',
              },
            };

            this.emitSideEffect({
              type: 'FireAnalyticsEvent',
              eventType: 'Mixpanel',
              eventName: 'login_unauthorized_error',
              payload: {
                Status: 'Unauthorized',
                login_using: 'normal',
              },
            });
          } else {
            responseMessage = {
              msg: trans('auth.exceededLoginAttempts'),
              status: 'error',
              iconMeta: {
                icon: 'assets/img/auth/danger-white.svg',
                position: 'before',
              },
            };

            this.emitSideEffect({
              type: 'FireAnalyticsEvent',
              eventType: 'Mixpanel',
              eventName: 'login_server_error',
              payload: {
                Status: 'Server Error',
                login_using: 'normal',
              },
            });
          }

          this.merge({
            isLoading: false,
            showResetPasswordError: true,
            responseMessage,
          });
        },
      });
  }

  private _signInWithGoogle(): void {
    this.set('isLoading', true);

    this._loginUserWithGoogleUseCase.execute().subscribe({
      next: async () => {
        this._appTracker.identifyUser();
        this._identifyMixpanelInstance();

        this._setSelectedProductsPageExperience();
        await countriesList.load();

        this._routeAfterLogin();

        this.emitSideEffect({
          type: 'FireAnalyticsEvent',
          eventType: 'Mixpanel',
          eventName: 'login_google',
          payload: {
            Status: 'Logged In',
            login_using: 'Google',
          },
        });

        this.emitSideEffect({
          type: 'FireAnalyticsEvent',
          eventType: 'Gtag',
          eventName: 'sign_in',
          payload: {
            'Taager ID': user.id,
            Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
            'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
          },
        });
      },
      error: (err) => {
        let responseMessage: SharedNotificationConfig | undefined;

        if (err.status === 403) {
          responseMessage = {
            msg: trans('auth.unauthorizedToAccess'),
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };

          this.emitSideEffect({
            type: 'FireAnalyticsEvent',
            eventType: 'Mixpanel',
            eventName: 'login_google_error',
            payload: {
              Status: 'this email is not registered before',
              login_using: 'Google',
            },
          });
        } else {
          responseMessage = {
            msg: trans('auth.invalidEmail'),
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };

          this.emitSideEffect({
            type: 'FireAnalyticsEvent',
            eventType: 'Mixpanel',
            eventName: 'login_google_unauthorized',
            payload: {
              Status: 'this user is unauthorized',
              login_using: 'Google',
            },
          });
        }

        this.merge({
          isLoading: false,
          showResetPasswordError: true,
          responseMessage,
        });
      },
    });
  }

  private _setSelectedProductsPageExperience(): void {
    this._setSelectedProductsPageExperienceUseCase.execute(
      this._checkUserNewExperienceEligibilityUseCase.execute(),
    );
  }

  private _identifyMixpanelInstance(): void {
    this._identifyMixpanelInstanceUseCase.execute();
  }

  /**
   * Okay, there are a couple of places that the user can be routed to after logging in.
   * We will resolve these and then whichever fires, will be the truth and executed.
   */
  private async _routeAfterLogin(): Promise<void> {
    this._mixpanelService.identify();

    const userToken = await firstValueFrom(this._getRegisterTokenUseCase.execute());
    const userRegistrationStep = this._userRegistrationStepUtility.currentUserStep(userToken);

    if (userRegistrationStep !== PossibleIndicatorSteps.signupFullyVerified) {
      navigateTo(REGISTER_URL);
      return;
    }

    let nextPath = '';

    nextPath = PRODUCTS_V2_URL;

    /**
     * So, since the only competing paths on initial login are products and recommended, we check
     * to see whether we can redirect the user to a path that is not products.
     */
    const redirectUrl = cache.get(REDIRECT_URL);
    if (redirectUrl) {
      nextPath = redirectUrl;
      cache.remove(REDIRECT_URL);
    }

    /**
     * now remove the redirection url
     */
    this._doLocationReplace(country.shortCode.toLowerCase(), nextPath);
  }

  /**
   *
   * @param selectedMarket
   * @param nextPath
   *
   * We will receive the selected market and the next path where the user should go to.
   */
  private _doLocationReplace(isoCode2: string, nextPath: string): void {
    if (nextPath) {
      location.replace(`${isoCode2}${nextPath.startsWith('/') ? '' : '/'}${nextPath}`);
    } else {
      location.replace(isoCode2);
    }
  }
}
