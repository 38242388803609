<div class="background">
  <div class="d-flex login-container flex-column align-items-center">
    <app-shared-notification-component
      *ngIf="viewState.responseMessage && !viewState.isLoading"
      [config]="viewState.responseMessage"
    ></app-shared-notification-component>
    <div class="login-header-container">
      <img
        class="login-icon"
        loading="lazy"
        src="{{ viewState.assetsRelativePath + 'login-lock.svg' }}"
      />
      <div class="title heading2--bold">{{ trans('auth.loginToAccount') }}</div>
    </div>
    <div class="d-flex btn-ssi justify-content-center align-items-center google-btn-container">
      <button
        [disabled]="viewState.isLoading"
        (click)="signInWithGoogle()"
        class="btn body2--medium"
      >
        <img
          loading="lazy"
          class="icon"
          src="{{ viewState.assetsRelativePath + 'google_logo.svg' }}"
        />
        {{ trans('auth.loginViaGoogle') }}
      </button>
    </div>

    <loader [loading]="viewState.isLoading" size="md"></loader>
    <div class="card-content" *ngIf="!viewState.isLoading">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <app-meta-phone-number-field
            (result$)="phoneNumberChanges($event)"
            [userPhoneNumber]="userPhoneNumber"
            *ngIf="viewState.selectedLoginMethod === 'phone'"
          ></app-meta-phone-number-field>

          <input
            class="form-control body2--medium"
            type="text"
            [placeholder]="trans('email')"
            formControlName="phoneNumberOrEmail"
            *ngIf="viewState.selectedLoginMethod === 'email'"
            data-test-id="login-form-email"
          />

          <div *ngIf="phoneNumberOrEmail.invalid && phoneNumberOrEmail.dirty">
            <div *ngIf="phoneNumberOrEmail.errors?.required" class="text-error">
              <div
                class="caption1--medium"
                *ngIf="viewState.selectedLoginMethod === 'email'; else emailRequired"
              >
                رقم الموبايل مطلوب
              </div>
              <ng-template class="caption1--medium" #emailRequired>
                البريد الإلكتروني مطلوب
              </ng-template>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="signUp-container__form__form-field__password-container">
            <input
              class="form-control body2--medium"
              [type]="viewState.showPassword ? 'text' : 'password'"
              [placeholder]="trans('password')"
              formControlName="password"
              data-test-id="login-form-password"
            />
            <img
              loading="lazy"
              class="signUp-container__form__form-field__password-eye"
              [src]="
                viewState.assetsRelativePath +
                (viewState.showPassword ? 'eye-slash.svg' : 'eye.svg')
              "
              (click)="showHidePassword()"
            />
          </div>
          <div *ngIf="password.invalid && password.dirty">
            <div *ngIf="password.errors?.required" class="text-error caption1--medium">
              كلمة المرور مطلوبة
            </div>
          </div>
        </div>
        <p *ngIf="viewState.showResetPasswordError" class="text-error body2--medium">
          رقم الهاتف أو كلمة المرور غير صحيحة. إذا نسيت كلمة المرور الخاصة بك، يرجى الضغط على “نسيت
          كلمة المرور” لتغييرها
        </p>
        <p class="forget-pass-link body2--medium" routerLink="/authv2/forgot-password">
          نسيت كلمة المرور
        </p>
        <button
          [disabled]="loginForm.invalid"
          class="btn auth-btn register-btn body2--medium"
          type="submit"
        >
          تسجيل الدخول
        </button>
      </form>
      <button
        class="btn auth-btn change-type-btn body2--medium"
        (click)="changeLoginType()"
        data-test-id="login-via-btn"
      >
        {{ viewState.loginText }}
      </button>
      <p class="register-text body2--regular">
        ليس لديك حساب؟
        <span class="register-link body2--bold" (click)="onGoToSignUpClicked()">
          إنشاء حساب جديد
        </span>
      </p>
    </div>
  </div>
</div>
