/* eslint-disable no-useless-escape */
import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BaseComponent } from '../../base/base.component';
import {
  MetaPhoneNumberFieldComponent,
  MetaPhoneNumberOutput,
} from '../../shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { SharedNotificationComponent } from '../shared/notification/shared.notification.component';
import { ForgotPasswordPresenter } from './presenter/forgot-password.presenter';
import { ForgotPasswordSideEffects } from './presenter/forgot-password.side-effects';
import { ForgotPasswordViewEvent } from './presenter/forgot-password.view-events';
import { ForgotPasswordViewState } from './presenter/forgot-password.view-state';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SharedNotificationComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatRadioModule,
    MetaPhoneNumberFieldComponent,
  ],
  providers: [ForgotPasswordPresenter],
})
export class ForgotPasswordComponent extends BaseComponent<
  ForgotPasswordPresenter,
  ForgotPasswordViewState,
  ForgotPasswordViewEvent,
  ForgotPasswordSideEffects
> {
  public presenter: ForgotPasswordPresenter = inject(ForgotPasswordPresenter);

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    countryCode: new UntypedFormControl(''),
    phoneNumber: new UntypedFormControl(''),
  });

  public onInit(): void {
    this.formValidation();
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  formValidation(): void {
    this.forgotPasswordForm.valueChanges.subscribe({
      next: (values) => {
        this.presenter.emitViewEvent({ type: 'FormValueChanges', values });
      },
    });
  }

  public phoneNumberChanges(ev: MetaPhoneNumberOutput): void {
    const { country, phoneNumber } = ev;
    this.forgotPasswordForm.get('countryCode')?.setValue(country.isoCode3);
    this.forgotPasswordForm.get('phoneNumber')?.setValue(phoneNumber);
  }

  switchResetMethod(method: 'email' | 'phone'): void {
    this.forgotPasswordForm.reset();
    this.presenter.emitViewEvent({ type: 'SwitchResetMethod', method });
  }

  onSubmit(): void {
    this.presenter.emitViewEvent({ type: 'Submit', form: this.forgotPasswordForm.value });
  }

  onSideEffect(sideEffect: ForgotPasswordSideEffects): void {
    switch (sideEffect.type) {
      case 'FireAnalyticsEvent': {
        this._logMixpanelEventUseCase.execute({
          eventName: sideEffect.eventName,
          payload: sideEffect.payload,
        });
        break;
      }
    }
  }
}
