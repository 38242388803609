import { Injectable } from '@angular/core';
import { ForgotPasswordUseCase } from 'src/app/core/usecases/auth/forgot-password.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { EMAIL_REGULAR_EXPRESSION } from 'src/app/presentation/shared/constants';
import { SharedNotificationConfig } from '../../shared/notification/shared.notification.component';
import { ForgotPasswordSideEffects } from './forgot-password.side-effects';
import { ForgotPasswordViewEvent } from './forgot-password.view-events';
import { ForgotPasswordViewState } from './forgot-password.view-state';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordPresenter extends BasePresenter<
  ForgotPasswordViewState,
  ForgotPasswordViewEvent,
  ForgotPasswordSideEffects
> {
  constructor(private _forgotPasswordUseCase: ForgotPasswordUseCase) {
    super();
  }

  protected defaultViewState(): ForgotPasswordViewState {
    return {
      isResetViaEmail: true,
      isLoading: false,
      formIsValid: false,
      responseMessage: undefined,
      assetsRelativePath: '/assets/img/auth/',
    };
  }

  protected onViewEvent(event: ForgotPasswordViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.emitSideEffect({
          type: 'FireAnalyticsEvent',
          eventName: 'forgot_password_page_load',
          payload: {
            Status: 'forgot password page',
          },
        });
        break;
      }
      case 'SwitchResetMethod': {
        this.updateViewState({
          ...this.viewState,
          isResetViaEmail: event.method === 'email',
          formIsValid: false,
        });
        break;
      }
      case 'FormValueChanges': {
        if (this.viewState.isResetViaEmail) {
          const emailRegex = new RegExp(EMAIL_REGULAR_EXPRESSION);
          this.updateViewState({
            ...this.viewState,
            formIsValid:
              event.values.email !== null &&
              event.values.email !== '' &&
              emailRegex.test(event.values.email),
          });
        } else if (!this.viewState.isResetViaEmail) {
          this.updateViewState({
            ...this.viewState,
            formIsValid: event.values.phoneNumber !== null && event.values.phoneNumber !== '',
          });
        } else {
          this.updateViewState({ ...this.viewState, formIsValid: false });
        }
        break;
      }

      case 'Submit': {
        this.submitResetpassword(event.form);
        break;
      }
    }
  }

  submitResetpassword(
    form: { email: string } | { phoneNumber: string; countryCode: string },
  ): void {
    let responseMessage: SharedNotificationConfig;
    this.updateViewState({ ...this.viewState, isLoading: true });
    this._forgotPasswordUseCase.execute(form).subscribe({
      next: (_) => {
        responseMessage = {
          msg: 'تم ارسال رابط تغيير كلمة المرور الى بريدك الالكتروني',
          status: 'success',
          iconMeta: {
            icon: 'assets/img/auth/success-check-white.svg',
            position: 'before',
          },
        };
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
          responseMessage,
        });
        this.emitSideEffect({
          type: 'FireAnalyticsEvent',
          eventName: 'forgot_password_successfully_sent',
          payload: {
            Status: 'forgot password successfully sent to the entered email',
            email: form,
          },
        });
      },
      error: (err) => {
        if (err.status === 404) {
          responseMessage = {
            msg: 'البريد الالكتروني المضاف غير صحيح',
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };
          this.emitSideEffect({
            type: 'FireAnalyticsEvent',
            eventName: 'forgot_password_wrong_email',
            payload: {
              Status: 'forgot password wrong entered email',
              email: form,
            },
          });
        } else {
          responseMessage = {
            msg: 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرى',
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };
          this.emitSideEffect({
            type: 'FireAnalyticsEvent',
            eventName: 'forgot_password_server_error',
            payload: {
              Status: 'forgot password server error',
              email: form,
            },
          });
        }
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
          responseMessage,
        });
      },
    });
  }
}
